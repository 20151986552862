import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Box, useToast } from "@chakra-ui/react";
import ProfileForm from "../../ui/Components/Form";
import Title from "../../ui/Components/Title";
import Description from "../../ui/Components/Description";
import { authStore } from "../../store/authStore";
import { ProfileFormData } from "./interfaces";
import { updatePassword, updateUser } from "../../api/Calls/form";
import { useLogto } from "@logto/react";
import { hasChanges } from "./utils/hasChange";
import { showToast } from "./utils/toastUtils";
import { useTranslation } from "react-i18next";
import useToastError from '../../hooks/useToastError/index';
import { useGetTokenOrganization } from "../../hooks/useGetAccessTokenOrganization";

const ProfileFormContainer: React.FC = () => {
    const { signOut } = useLogto();
    const { t } = useTranslation();
    const toast = useToast();
    const infoUser = authStore((state) => state.infoUser.email);
    const userName = authStore((state) => state.userName);
    const language = authStore((state) => state.language);
    const { fetchUpdatedToken } = useGetTokenOrganization();
    const { register, handleSubmit, formState: { errors, isSubmitting }, reset } = useForm<ProfileFormData>({
        defaultValues: {
            email: infoUser || "",
            name: userName || "",
            language: language || '',

        }
    });
    const { setUserName, setLanguage } = authStore.getState();
    const [initialData, setInitialData] = useState<ProfileFormData>({
        email: infoUser || "",
        name: userName || "",
        old_password: "",
        new_password: "",
        language: language || "",
    });
    const showToastError = useToastError();


    useEffect(() => {
        reset({
            email: infoUser,
            name: userName,
            language: language,
        });
    }, [infoUser, userName, language, reset]);
 

    const onSubmit: SubmitHandler<ProfileFormData> = async (data) => {
        const { nameChanged, passwordChanged, languageChanged } = hasChanges(data, initialData)
           
    
        let userUpdateSuccess = false;
        let passwordUpdateSuccess = false;
        if (nameChanged || passwordChanged || languageChanged) {
            try {
                const token = await fetchUpdatedToken();
                if (!token) throw new Error("Failed to obtain access token.");
        
                if(nameChanged || languageChanged ){
                    try {
                        await updateUser(token, { name: data.name, language: data.language }, signOut, showToastError);
                        userUpdateSuccess = true;
                        setUserName(data.name);
                        setLanguage(data.language);
                        setInitialData({
                            ...initialData,
                            name: data.name,
                            language: data.language,
                        });
                    } catch (error) {
                        console.error("Error updating profile:", error);
                    }
                } else if (passwordChanged && data.old_password && data.new_password) {
                    try {
                        await updatePassword(token, { old_password: data.old_password, new_password: data.new_password }, signOut, showToastError);
                        passwordUpdateSuccess = true;
                    } catch (error) {
                        console.error("Error updating password:", error);
                    }
                }
    
                if(passwordChanged && !passwordUpdateSuccess){
                    showToast(toast, t, "error");
                }
                else if((nameChanged || languageChanged) && !userUpdateSuccess){
                    showToast(toast, t, "error");
                }
        
                else if(userUpdateSuccess && passwordUpdateSuccess) {
                    showToast(toast, t, "success");
                } else if (userUpdateSuccess){
                    showToast(toast, t, "successUser");
                } else if (passwordUpdateSuccess){
                    showToast(toast, t, "successPassword");
                } else {
                    showToast(toast, t, "error");
                }
        
                if (userUpdateSuccess || passwordUpdateSuccess) {
                    reset();
                }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            } catch (error) {
                showToast(toast, t, "requestError");
            } finally {
                setInitialData({
                    ...initialData,
                    name: data.name,
                    language: data.language,
                });
            }
        }
    
   
     
    };


    const handleCancel = () => {
        if (initialData) {
            reset(initialData);
        }
    };

    return (
        <Box>
            <Title title={t("personalInformation")}  />
            <Description description={t("youCanChangeAccountSettingsHere")} />
            <ProfileForm
                onSubmit={handleSubmit(onSubmit)}
                register={register}
                errors={errors}
                handleCancel={handleCancel}
                isSubmitting={isSubmitting}
            />
        </Box>
    );
};

export default ProfileFormContainer;
