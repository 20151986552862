import { useLogto } from "@logto/react";
import {fetchManagment} from '../api/Calls/managment';

import { useCallback } from "react";
import useToastError from './useToastError'
import { useGetTokenOrganization } from "./useGetAccessTokenOrganization";

export const useRedirectStripe = () => {
    const { signOut } = useLogto();
    const showToastError = useToastError();
    const { fetchUpdatedToken } = useGetTokenOrganization();

    const fetchAndRedirect = useCallback(async (product: string) => {
        try {
            const token = await fetchUpdatedToken();
            if (!token) {
                console.error("Token is undefined");
                return;
            }
            const response = await fetchManagment(token, product.toLowerCase(), signOut, showToastError);
            const redirectUrl = response.url;
            
            if (redirectUrl) {
                window.location.href = redirectUrl;
            } else {
                console.error("No redirect URL found in response");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchUpdatedToken]);

    return fetchAndRedirect;
};
