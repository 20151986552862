import {useLogto} from "@logto/react";
import {authStore} from "../store/authStore";
import {useEffect} from "react";
import { useGetTokenOrganization } from "./useGetAccessTokenOrganization";

export const useGetAccessToken = () => {
    const { isAuthenticated } = useLogto();
    const { accessToken, setAccessToken } = authStore.getState();
    const { fetchUpdatedToken } = useGetTokenOrganization();
    useEffect(() => {
        const fetchToken = async () => {
            if (isAuthenticated && !accessToken) {
                const token = await fetchUpdatedToken();
                setAccessToken(token || '');
            }
        };

        fetchToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken, isAuthenticated, setAccessToken]);

    return accessToken;
};