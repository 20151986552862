import axios, { AxiosError } from 'axios';


const createApiClient = (token: string, signOut: () => Promise<void>, showToast: (message: string) => void) => {
    const client = axios.create({
        baseURL: `${import.meta.env.VITE_API_ENDPOINT}/api/user`,
        timeout: 4000,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
    });

    let isToastVisible = false;

    client.interceptors.response.use(
        response => response,
        async (error: AxiosError) => {
            if (error.response?.status === 401) {
                await signOut();
            }  else {
                if (!isToastVisible) {
                    showToast("Hubo un error. Por favor, inténtelo de nuevo en unos minutos.");
                    isToastVisible = true;
                    setTimeout(() => {
                        isToastVisible = false;
                    }, 5000);
                }
                console.error("Error en la petición:", error.message);
            }
            return Promise.reject(error);
        }
    );

    return client;
};

export default createApiClient;
