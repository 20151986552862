import React, { useEffect, useState } from "react";
import { Box, SimpleGrid, Spinner, Flex, Text } from "@chakra-ui/react";
import Title from '../../ui/Components/Title';
import Description from '../../ui/Components/Description';
import CardsPorcentage from "../../ui/Components/CardPorcentage";
import InactiveSubcription from "../../ui/Components/InactiveSubcription";
import { fetchAllPlans } from "../../api/Calls/allProducts";
import { fetchMeters } from "../../api/Calls/meters";
import { useLogto } from "@logto/react";
import { PlansData, PlansDataActive, PlansDataInactive,  PlansActiveWithMeters } from "./interfaces";
import { useRedirectStripe } from "../../hooks/useRedirectStripe";
import useToastError from '../../hooks/useToastError/index';
import { useTranslation } from "react-i18next";
import { useGetTokenOrganization } from "../../hooks/useGetAccessTokenOrganization";
 

const PlanAndBilling: React.FC = () => {
    const [activePlansWithMeters, setActivePlansWithMeters] = useState<PlansActiveWithMeters[]>([]);
    const [inactivePlans, setInactivePlans ] = useState<PlansDataInactive[]>([])
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { signOut } = useLogto()
    const redirectToStripe = useRedirectStripe();
    const showToastError = useToastError();
    const { t } = useTranslation();
    const { fetchUpdatedToken } = useGetTokenOrganization();

    const initializePlans = async () => {
        try {
            const token = await fetchUpdatedToken();
            if (!token) throw new Error("Failed to obtain access token.");

            const data = await fetchAllPlans(token, signOut, showToastError);
            const plansActive = data.filter((plan: { plan: PlansData; }) => plan.plan !== null) as PlansDataActive[];
            const plansInactive = data.filter((plan: { plan: PlansData; }) => plan.plan === null) as PlansDataInactive[];
            setInactivePlans(plansInactive)
            const meterPromises = plansActive.map((plan) => fetchMeters(token, plan.product, signOut, showToastError));
            const metersData = await Promise.all(meterPromises);
            const updatedPlans = plansActive.map(plan => {
                const meterData = metersData.find(meter => meter.product === plan.product);
                return {
                    ...plan,
                    remaining: meterData ? meterData.remaining : 0,
                    total: meterData ? meterData.total : 0
                };
            });
            setActivePlansWithMeters(updatedPlans);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
            setError(t("errorAPI"));
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        initializePlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    if (error) return <p>{error}</p>;

    return (
        <Box maxW="1350px" mx="auto" > 
            <Title title="planAndBilling"  />
            {!isLoading && activePlansWithMeters ?
                <Box mb="90px" >
                    <Description description="activeSubscriptions"/>
                    <SimpleGrid
                        columns={{ base: 1, md: 2}} 
                        minChildWidth="550px"
                        spacing="25px"  
                        justifyItems="start" 
                        maxW="100%" 
                        w="100%"
                    > { activePlansWithMeters.map((plan) => (
                            <CardsPorcentage
                                key={plan.product}
                                title={plan.product}
                                plan={plan.plan}
                                price={null}
                                creditsUsed={plan.remaining}
                                totalCredits={plan.total}
                                onManageSubscription={() => redirectToStripe(plan.product)}
                            />
                        )) }
             

                    </SimpleGrid>
                </Box>
                : <Flex minHeight="500px" minW="200px" alignItems="center" justifyContent="center"><Spinner color="teal.500" size="lg" /></Flex>

            }
            <Box mb="90px">
                <Title title="inactiveSubscriptions"  />
                {!isLoading && inactivePlans.length > 0
                    ?
                    <SimpleGrid  
                        columns={{ base: 1, md: 2}} 
                        minChildWidth="550px"
                        spacing="25px"  
                        justifyItems="start" 
                        maxW="100%" 
                        w="100%">
                        {inactivePlans?.map((plan) => (
                            <InactiveSubcription
                                key={plan.product}
                                title={plan.product}
                                onManageSubscription={() => redirectToStripe(plan.product)}
                            />
                        ))}

                    </SimpleGrid>
                    : !isLoading && inactivePlans.length === 0 ?
                        <Flex minHeight="250px" minW="200px" alignItems="center" justifyContent="center">
                            <Text>{t("inactiveSubscriptions")} </Text>
                        </Flex>
                        :
                        <Flex minHeight="500px" minW="200px" alignItems="center" justifyContent="center">
                            <Spinner color="teal.500" size="lg" />
                        </Flex> 
                }
            </Box>
          
        </Box>

    
    );
};

export default PlanAndBilling;
