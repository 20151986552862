import { useLogto } from "@logto/react";
import { useState, useEffect, useCallback } from "react";

export const useGetTokenOrganization = () => {
    const { isAuthenticated, getAccessToken } = useLogto();
    const [accessToken, setAccessToken] = useState<string>("");
    const organizationId = "i3isplfdk800";
 
    const fetchUpdatedToken = useCallback(async () => {
        if (isAuthenticated) {
            const token = await getAccessToken(import.meta.env.VITE_LOGTO_API_ID, organizationId);
            setAccessToken(token || "");
            return token; 
        }
        return null;
    }, [isAuthenticated, getAccessToken]);

    useEffect(() => {
        fetchUpdatedToken();
    }, [fetchUpdatedToken]);

    return { accessToken, fetchUpdatedToken };
};
