import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { LogtoProvider, LogtoConfig, UserScope } from '@logto/react'
import {BrowserRouter} from "react-router-dom"
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import './translator/i18n.ts';

const logtoConfig: LogtoConfig = {
    endpoint: import.meta.env.VITE_LOGTO_ENDPOINT,
    appId: import.meta.env.VITE_LOGTO_APP_ID,
    scopes: [
        "all", "admin",
        "write:organization",
        "read:organization",
        "write:billing",
        UserScope.Email,
        UserScope.CustomData,
        UserScope.Profile,
        UserScope.Organizations,
    ],
    resources: [
        import.meta.env.VITE_LOGTO_API_ID
    ]
}

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <LogtoProvider config={logtoConfig}>
            <ChakraProvider theme={theme}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </ChakraProvider>
        </LogtoProvider>
    </React.StrictMode>,
)
